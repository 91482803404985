import { inject, Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';

import { DivonoDashboardConfigApiService } from '@openapi';
import { queryOptions } from '@tanstack/angular-query-experimental';

import { AuthService } from '@core/services/auth/auth.service';
import { FeatureFlags, FeatureFlagService } from '@core/services/feature-flag.service';

import { DivonoTokenService } from '@divono/services/divono-token.service';

@Injectable({
  providedIn: 'root'
})
export class DivonoTopicService {
  #authService: AuthService = inject(AuthService);
  #divonoTokenService: DivonoTokenService = inject(DivonoTokenService);
  #divonoDashboardConfigService: DivonoDashboardConfigApiService = inject(DivonoDashboardConfigApiService);
  #featureFlagService: FeatureFlagService = inject(FeatureFlagService);

  readonly #STALE_TIME = 3_600_000;

  public queryOptions = () =>
    queryOptions({
      queryKey: ['divonoTopics'],
      staleTime: this.#STALE_TIME,
      queryFn: () =>
        lastValueFrom(
          this.#divonoDashboardConfigService.getDivonoDashboardConfig(this.#divonoTokenService.divonoToken())
        ),
      enabled: this.#featureFlagService.isFeatureEnabled(FeatureFlags.DIVONO) && this.#authService.divonoTokenSet(),
      refetchInterval: this.#STALE_TIME,
      refetchIntervalInBackground: true,
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      refetchOnWindowFocus: 'always'
    });
}
